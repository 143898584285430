import HttpClient from "../../clients/HttpClient";

export enum PaymentSheetRowType {
  RETURN_PAYMENT = "return_payment",
  INITIAL_PAYMENT = "initial_payment"
}

class PaymentSheetRow {
    booking_id: number
    processor_reference: string
    type: PaymentSheetRowType
}

export class PaymentSheetDTO  {
    payment_sheet: PaymentSheetRow[]
}

export default class ReportService {
  static httpClient: HttpClient = HttpClient.getInstance("backend", process.env.REACT_APP_API_URL);

  public static async getExcelSheet(payload:{startDate:Date, endDate:Date, tspId:number}): Promise<PaymentSheetDTO> {
    return this.httpClient.post(`/reports/get_payment_sheet`,payload).then((res) => {
      return res.data;
    });
  }

}
