import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { OverlayContext } from "../../../context/OverlayContext";
import { ToastContext } from "../../../context/ToastContext";
import { NavigationContext } from "../../../context/NavigationContext";
import { Table, Typography, Button, Form, List, Tag, Tooltip, Input } from "antd";
import { ArrowLeftOutlined, StopOutlined } from "@ant-design/icons";
import UserService from "../../../services/crud/UserService";
// import UserForm from "../components/UserForm";
// import DeleteForm from "../components/DeleteForm";
import User from "../../../models/User";
import UserForm from "../../../components/forms/UserForm";

import * as _ from "lodash";

import BookingsTable, { BookingTableData } from "../../../components/tables/BookingsTable";
import SubscriptionsTable, { GenericSubscriptionsTable } from "../../../components/tables/SubscriptionsTable";
import Subscription from "../../../models/Subscription";
import { ViewTitle } from "../../../components/ViewTitle";
import DescriptionDataList from "../../../components/DescriptionDataList";
import AccountService from "../../../services/crud/AccountService";
import SubscriptionService from "../../../services/crud/SubscriptionService";
import Account from "../../../models/Account";
import { GoBackButton } from "../../../components/GoBackButton";

function AccountsTable({ userId, columnFilter = () => true, dataFilter = () => true }) {
  /// A function (account) => boolean, that is passed to filter() function
  /// to filter out which accounts to keep.
  // Similarly an optional function to filter which columns to keep
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [filter, setFilter] = useState("");
  const { loading, loadMessage } = useContext(ToastContext);

  // const navigate = useNavigate();
  // const gotoPage = (route) => {
  //   console.log("route is: " + route);
  //   navigate("/accounts/" + route);
  // };
  function loadTable() {
    if (userId) {
      // Load all accounts for user
      loadMessage("Loading Accounts for user...", () =>
        Promise.all([AccountService.listAccountsOnUser(userId)]).then((data) => {
          const accounts = data[0].filter(dataFilter);
          setAccounts(accounts);
        })
      );
    } else {
      // Load all accounts
      loadMessage("Loading Accounts...", () =>
        Promise.all([AccountService.list()]).then((data) => {
          const accounts = data[0].filter(dataFilter);
          setAccounts(accounts);
        })
      );
    }
  }
  useEffect(() => {
    loadTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterTable = (data) => data.filter((item) => new RegExp(filter.toLowerCase()).test(item.toString().toLowerCase()));
  // Create columns for each property in data

  var columns: any = [
    {
      title: "Name",
      key: "name", // Used by UserProfile via columnFilter prop
      dataIndex: ["name"],
      render: (text, record, index) => {
        return <Tooltip title={`${record?.name ?? ""}`.trim()}>{record?.name ?? ""}</Tooltip>;
      },
      sorter: (a, b) => a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase()),
    },
    {
      title: "Type",
      key: "type",
      dataIndex: ["type"],
      render: (text, record, index) => {
        return <Tooltip title={`${record?.type ?? ""}`.trim()}>{record?.type ?? ""}</Tooltip>;
      },
      sorter: (a, b) => a?.type?.toLowerCase().localeCompare(b?.type?.toLowerCase()),
    },

    {
      title: "Balance",
      key: "balance",
      dataIndex: ["balance"],
      render: (text, record, index) => {
        return <Tooltip title={`${record?.balance ?? ""}`.trim()}>{record?.balance ?? ""}</Tooltip>;
      },
      sorter: (a, b) => a.balance - b.balance,
    },

    {
      title: "Created",
      dataIndex: "createdAt",
      render: (text, record, index) => {
        return `${record.createdAtAsDate}`;
      },
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    },
  ];
  // Filters
  const filterColumns = (columns = []) => columns.filter(columnFilter);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 24 }}>
        <div>
          <Input.Search size="middle" placeholder="Search accounts..." style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} />
        </div>
      </div>
      <Table
        rowKey="id"
        columns={filterColumns(columns)}
        dataSource={filterTable(accounts)}
        pagination={{ total: accounts.length, defaultPageSize: 30, showSizeChanger: true }}
        // onRow={(record) => ({ onClick: () => gotoPage(record.id) })}
        loading={loading}
      />
      <Typography.Text style={{ position: "relative", top: -43, left: 10, color: "#bfbfbf" }}>Total of {accounts.length} accounts</Typography.Text>
    </div>
  );
}

const initUser = {
  username: "loading",
  password: "",
  addressLine1: "",
  addressLine2: "",
  bankidRef: "",
  city: "",
  driverLicenseNumber: "",
  email: "",
  firstName: "",
  lastName: "",
  personalId: "",
  phoneNumber: "",
  role: "",
  zipCode: "",
};

function UserView(props) {
  let navigate = useNavigate();
  const gotoPage = (route) => {
    console.log("route is: " + route);
    navigate(route);
  };
  const { id } = useParams<string>();
  const userId = parseInt(id);
  const [user, setUser] = useState<User>(new User(initUser));

  const { openModal, closeModal } = useContext(OverlayContext);
  const { loadMessage } = useContext(ToastContext);
  const [form] = Form.useForm();

  useEffect(() => {
    loadMessage("Loading user...", () =>
      Promise.all([UserService.getUser(id)]).then((data) => {
        setUser(data[0]);
        return true;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openUserUpdateModal = (user = null) => {
    openModal({
      title: "Update user",
      content: <UserForm id={userId} form={form} update={true} />,
      onOk: () => {
        form.validateFields().then(async (values) => {
          closeModal();
          loadMessage("Updating user", () =>
            UserService.editUser(
              values.id,
              _.pickBy(values, (x) => x !== null)
            )
              .then((user) => {
                setUser(user);
                return "User updated";
              })
              .catch((res) => {
                throw Error("Could not update user");
              })
          );
        });
      },
    });
  };
  // Filters
  const bookingDataFilter = (data: BookingTableData) => {
    return data.userId === userId;
  };

  const handleEdit = () => {
    openUserUpdateModal(user);
  };
  const title = user.firstName + " " + user.lastName;
  const descriptionData = {
    "username/email": user.username,
    name: user.firstName + " " + user.lastName,
    "Personal Id": user.personalId,
    address: user.addressLine1,
    bankidRef: user.bankidRef,
    phoneNumber: user.phoneNumber,
    zipCode: user.zipCode,
    city: user.city,
    role: user.role,
    "user id": userId,
  };

  return (
    <div style={{ position: "relative" }}>
      <GoBackButton></GoBackButton>

      {/* USER DATA */}
      <div>
        <ViewTitle title={title}></ViewTitle>
        <DescriptionDataList
          data={descriptionData}
          footer={
            <Button size="small" type="link" onClick={handleEdit}>
              Edit profile
            </Button>
          }
        />
      </div>

      {/* SUBSCRIPTIONS */}
      <Typography.Title level={3} style={{ marginTop: 40, marginLeft: 10 }}>
        Subscriptions
      </Typography.Title>
      <SubscriptionsTable params={{ userId }}></SubscriptionsTable>
      {/* BOOKINGS */}
      <Typography.Title level={3} style={{ marginTop: 40, marginLeft: 10 }}>
        Bookings
      </Typography.Title>
      <BookingsTable dataFilter={bookingDataFilter} columnFilter={(col) => col.key !== "userId"}></BookingsTable>
      {/* ACCOUNTS */}
      <Typography.Title level={3} style={{ marginTop: 40, marginLeft: 10 }}>
        Accounts
      </Typography.Title>
      <AccountsTable userId={userId}></AccountsTable>
    </div>
  );
}

export default UserView;
