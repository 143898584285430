import { Alert, Table, Tooltip, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountForms } from "../../../components/forms/AccountForm";
import InfoBox from "../../../components/InfoBox";
import { GenericAccountsTable } from "../../../components/tables/AccountsTable";
import { GenericTableActions } from "../../../components/tables/types";
import { NavigationContext } from "../../../context/NavigationContext";
import { ToastContext } from "../../../context/ToastContext";
import Account from "../../../models/Account";
import User from "../../../models/User";
import { Modes } from "../../../Modes";
import AccountService from "../../../services/crud/AccountService";

// const includeColumns = ["name", "users", "hubs", "subscriptions"];

const adminActions: GenericTableActions = { add: true, delete: true, view: true, select: true };
type Props = {};
/**
 * This page lists all company accounts.
 * @param param0
 * @returns
 */

function CompanyAccounts({}: Props) {
  const { user, mode } = useContext(NavigationContext);
  const navigate = useNavigate();
  // CASE: USER HAS ONE COMPANY ACCOUNT
  useEffect(() => {
    switch (mode) {
      case Modes.SYSTEM_ADMIN:
        break;
      case Modes.COMPANY_ADMIN:
        AccountService.list({ userId: user?.id, type: Account.types.COMPANY }).then((accounts) => {
          if (accounts.length === 1) {
            navigate("/companies/" + accounts[0].id);
          }
        });
        break;
      case Modes.REAL_ESTATE_ADMIN:
        AccountService.list({ userId: user?.id, type: Account.types.REAL_ESTATE }).then((accounts) => {
          if (accounts.length === 1) {
            navigate("/realEstate/" + accounts[0].id);
          }
        });
        break;
    }
  }, [user, mode, navigate]);

  let accountsTable = <></>;
  switch (mode) {
    case Modes.SYSTEM_ADMIN:
      accountsTable = (
        <GenericAccountsTable
          formCreate={AccountForms.CompanyCreate}
          actions={adminActions}
          params={{ type: Account.types.COMPANY }}
          onView={(account) => {
            navigate("/companies/" + account.id);
          }}
          excludeColumns={["type", "balance"]}
        ></GenericAccountsTable>
      );
      break;
    case Modes.COMPANY_ADMIN:
      accountsTable = <AccountsTable params={{ type: Account.types.COMPANY, userId: user?.id }}></AccountsTable>;
      break;
    case Modes.REAL_ESTATE_ADMIN:
      accountsTable = <AccountsTable params={{ type: Account.types.COMPANY, userId: user?.id }}></AccountsTable>;
      break;
  }
  return (
    <div>
      <Typography.Title>Companies</Typography.Title>
      {accountsTable}
    </div>
  );
}

/**
 * This table loads data from backend and filters based on dataFilter and columnFilter.
 * @param {number} userId - default undefined. If defined loads alla accounts related to user
 * @param {function} dataFilter - filters data
 * @param {function} columnFilter - filter columns based on key
 * @returns table populated with accounts
 */
function AccountsTable({ params = {}, userId = undefined, dataFilter = (data: Account) => true, columnFilter = (col) => true }) {
  /// A function (account) => boolean, that is passed to filter() function
  /// to filter out which accounts to keep.
  // Similarly an optional function to filter which columns to keep
  const { user } = useContext(NavigationContext);
  const [accounts, setAccounts] = useState<Array<Account>>([]);
  const [filter, setFilter] = useState("");

  const { loading, loadMessage } = useContext(ToastContext);

  const navigate = useNavigate();
  const gotoPage = (route) => {
    console.log("route is: " + route);
    navigate("/companies/" + route);
  };
  function loadTable(user) {
    if (user) {
      loadMessage("Loading Accounts...", () =>
        Promise.all([AccountService.list(params)]).then((data) => {
          const accounts = data[0].filter(dataFilter);
          accounts.sort((a, b) => a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase()));
          setAccounts(accounts);
        })
      );
    }
  }
  useEffect(() => {
    loadTable(user);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const filterTable = (data) => data.filter((item) => new RegExp(filter.toLowerCase()).test(item.toString().toLowerCase()));

  var columns: any = [
    {
      title: "Name",
      key: "name", // Used by UserProfile via columnFilter prop
      dataIndex: ["name"],
      render: (text, record, index) => {
        return <Tooltip title={`${record?.name ?? ""}`.trim()}>{record?.name ?? ""}</Tooltip>;
      },
      sorter: (a, b) => a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase()),
    },
    {
      title: "Users",
      key: "users",
      dataIndex: ["users"],
      render: (text, record, index) => {
        return <Typography.Text>{text?.length || 0}</Typography.Text>;
      },
      sorter: (a, b) => (a?.users?.length ?? 0) - (b?.users?.length ?? 0),
    },

    {
      title: "Hubs",
      key: "hubs",
      dataIndex: ["hubs"],
      render: (text, record, index) => {
        return <Typography.Text>{text?.length || 0}</Typography.Text>;
      },
      sorter: (a, b) => (a?.hubs?.length ?? 0) - (b?.users?.hubs ?? 0),
    },

//    {
//      title: "Mobility Services",
//      dataIndex: ["subscriptions"],
//      render: (text, record, index) => {
//        return <Typography.Text>{text?.length || 0}</Typography.Text>;
//      },
//      sorter: (a, b) => (a?.subscriptions?.length ?? 0) - (b?.users?.subscriptions ?? 0),
//    },
  ];
  // Filters
  const filterColumns = (columns = []) => columns.filter(columnFilter);

  return (
    <div>
      {/* <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 24 }}>
        <div>
          <Popconfirm
            title={"You are about to delete " + selectedRows.length + " accounts!"}
            open={open}
            onConfirm={handleOk}
            okButtonProps={{ loading: confirmLoading }}
            onCancel={handleCancel}
          >
            <Button type="primary" onClick={showPopconfirm}>
              Delete Accounts
            </Button>
          </Popconfirm>
          <Input.Search size="middle" placeholder="Search accounts..." style={{ width: 200 }} onChange={(e) => setFilter(e.target.value)} />
          <span style={{ marginLeft: 8 }}>{hasSelected ? `Selected ${selectedRows.length} items` : ""}</span>
        </div>
        <Button type="primary" size="middle" onClick={() => openCreateModal()}>
          Create new account
        </Button>
      </div> */}

      <Table
        bordered
        rowKey="id"
        // rowSelection={rowSelection}
        columns={filterColumns(columns)}
        dataSource={filterTable(accounts)}
        // pagination={{ total: accounts.length, defaultPageSize: 30, showSizeChanger: true }}
        onRow={(record) => ({ onClick: () => gotoPage(record.id) })}
        loading={loading}
      />
      {/* <Typography.Text style={{ position: "relative", top: -43, left: 10, color: "#bfbfbf" }}>Total of {accounts.length} accounts</Typography.Text> */}
    </div>
  );
}
export default CompanyAccounts;
