
import BaseModel from "./BaseModel";

class UserFeedback extends BaseModel {
  id: number;
  bookingsExternalId: number;
  rating: number; // 1 to 5
  feedbackText?: string|null; // optional user feedback
  createdAt: Date|null;

  constructor(data) {
    super(data, []);
    this.id = data.id;
    this.bookingsExternalId = data.bookingsExternalId;
    this.rating = data.rating;
    this.feedbackText = data.feedbackText;
    this.createdAt = data.createdAt !== null && data.createdAt !== undefined
        ? new Date(data.createdAt)
        : null;
  }
}

export default UserFeedback;
