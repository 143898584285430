import axios, { CancelTokenSource } from "axios";
import spec from "../../package.json";
const FRONTEND_VERSION_TYPE = "admin-browser";
const FRONTEND_VERSION_NUMBER = spec.version;
class HttpClient {
  static instances: Object = {};
  abortController: AbortController;
  source: CancelTokenSource;
  baseUri: string = "";
  token: string = "";
  name: string = "";
  defaultHeaders = {
    "frontend-version-type": FRONTEND_VERSION_TYPE,
    "frontend-version-number": FRONTEND_VERSION_NUMBER,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "POST,GET,OPTIONS, PUT, DELETE",
    "Access-Control-Allow-Headers":
      "frontend-version-number,frontend-version-type, Accept, Content-Type, Content-Length, Accept-Encoding, X-CSRF-Token, Authorization",
  };

  constructor(name, baseUri) {
    if (baseUri) {
      this.baseUri = baseUri;
    }
    this.name = name;
  }

  public static getInstance(name, baseUri = null) {
    if (!(HttpClient.instances[name] instanceof HttpClient)) {
      HttpClient.instances[name] = new HttpClient(name, baseUri);
    }
    return HttpClient.instances[name];
  }

  public setDefaultHeaders(defaultHeaders) {
    this.defaultHeaders = defaultHeaders;
  }

  public getAuthHeader() {
    return {
      Authorization: "Bearer " + localStorage.getItem(this.name + "-token"),
    };
  }

  public authenticate(token) {
    if (token !== null) {
      this.token = token;
      localStorage.setItem(`${this.name}-token`, token);
    }
  }

  public invalidate() {
    this.token = null;
    localStorage.removeItem(`${this.name}-token`);
  }

  public isAuthenticated() {
    const token = localStorage.getItem(`${this.name}-token`) || null;
    return token !== null && token !== null && token.length > 0;
  }

  public async call(method, path, payload = {}, params = {}, headers = {}) {
    // Token and CancelToken
    this.source = axios.CancelToken.source();
    this.abortController = new AbortController();
    if (!this.token) {
      const token = localStorage.getItem(`${this.name}-token`) || null;
      this.authenticate(token);
    }

    const url = this.baseUri + path;
    headers = {
      Authorization: "Bearer: " + this.token,
      ...this.defaultHeaders,
      ...headers,
    };

    console.log(`HttpClient: ${method} ${url} ${JSON.stringify(payload).substring(0, 80)}...`);
    console.log(JSON.stringify(headers));
    // console.log(JSON.stringify(this.defaultHeaders))

    switch (method) {
      case "GET":
        return await axios.get(url, { params: params, headers: headers, cancelToken: this.source.token, signal: this.abortController.signal });
      case "POST":
        return await axios.post(url, payload, {
          headers: headers,
          cancelToken: this.source.token,
          signal: this.abortController.signal,
        });
      case "PUT":
        return await axios.put(url, payload, {
          headers: headers,
          cancelToken: this.source.token,
          signal: this.abortController.signal,
        });
      case "DELETE":
        return await axios.delete(url, {
          headers: headers,
          cancelToken: this.source.token,
          signal: this.abortController.signal,
          data: payload,
        });
      default:
        console.log("HttpClient: not a valid method: " + method);
    }
  }

  public async get(url, params = {}) {
    return await this.call("GET", url, undefined, params);
  }
  public async post(url, payload) {
    return await this.call("POST", url, payload);
  }
  public async put(url, payload) {
    return await this.call("PUT", url, payload);
  }
  public async patch(url, payload) {
    return await this.call("PUT", url, payload);
  }
  public async delete(url, payload?) {
    return await this.call("DELETE", url, payload);
  }

  public async cancel() {
    this.source.cancel("Operation canceled by the user."); // await?
    // this.abortController.abort();
  }
}

export default HttpClient;
